import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { settingsSiteMetadata } = useStaticQuery(
    graphql`
      {
        settingsSiteMetadata {
          title
          description
          author
          organization
          facebookAppID
          image
          owner
          titleTemplate
          twitterUsername
          twitterCardType
          url
        }
      }
    `,
  );
  return settingsSiteMetadata;
};
